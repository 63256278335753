import OpenClose from "../OpenClose/OpenClose"
import Button from "../Button/Button";

const $ = require('dom-helpers')

class Bullet extends OpenClose {
    constructor(slide, container, slideshow) {
        const button  = new Button("li")
        super(button.el)
        this.button = button
        this.el = this.button.el
        this.slide = slide
        this.slideshow = slideshow
        this.container = container
        this.button.appendTo(this.container)
        this.button.addListeners(this.openItem.bind(this))
    }

    openItem() {
        this.slideshow.openItem(this.slide)
    }
}

export default Bullet