import selectScss from './Select.scss'
import $ from "dom-helpers"

class Select {
    constructor(selector) {
        this.container = $.querySelectorAll(document, selector)[0]
        if(!this.container) return
        this.select = $.querySelectorAll(this.container, "select")[0]

        this.addListeners()
    }

    addListeners() {
        $.on(this.select, "change", this.goURL)
    }

    goURL(e) {
        location.href = e.target.value
    }
}

export default Select