class Tracking {
	constructor(props) {
		this.push = this.push.bind(this)

		this.eventCategory = props.eventCategory
		this.eventAction = props.eventAction
		this.eventLabel = props.eventLabel
	}

	push() {
		window.dataLayer = window.dataLayer || []

		window.dataLayer.push({
			'event': 'customEvent',
			'eventCategory': this.eventCategory,
			'eventAction': this.eventAction,
			'eventLabel': this.eventLabel,
		})
	}

}

export default Tracking