import slideshowScss from "./Slideshow.scss"
import Pager from "../Pager/Pager"
import Bullet from "./Bullet"
import Hammer from "hammerjs"

const $ = require("dom-helpers")
$.animate = require("dom-helpers/transition/animate")
$.end = require("dom-helpers/transition/end")
$.removeStyle = require("dom-helpers/style/removeStyle")

let childrenSelector = ".slide"
let slideContainerSelector = ".slides"
let bulletsContainerSelector = ".bullets"

class Animator {
    constructor(slideshow) {
        this.endAnimation = this.endAnimation.bind(this)
        this.next = this.next.bind(this)
        this.prev = this.prev.bind(this)
        this.animate = this.animate.bind(this)
        this.el = slideshow.slidesContainer
        this.slideshow = slideshow
        this.animDuration = 200
        this.easing = "ease-out"
        $.on(this.el, 'touchstart', this.touchMove.bind(this))
    }

    get targets() {
        const cur = this.slideshow.getCurrentlyOpen()
        return [
            this.slideshow.getPreviousItem(cur).el,
            cur.el,
            this.slideshow.getNextItem(cur).el
        ]
    }

    touchMove(e) {
        if(this.animation){
            this.animation = null
            delete this.animation
        }

        this.animation = new Sequence(this.el, e, this.targets, this.animDuration, this)
    }

    brakeReturn() {
        this.animation && this.animation.brakeReturn()
    }

    next(item, cur) {
        this.brakeReturn()
        if($.style(item.el, "transform") == "none") $.style(item.el, {transform: `translateX(100%)`})
        this.animate(item, cur)
        $.animate(cur.el, {transform: `translateX(-100%)`}, this.animDuration, this.easing)
    }

    prev(item, cur) {
        this.brakeReturn()
        if($.style(item.el, "transform") == "none") $.style(item.el, {transform: `translateX(-100%)`})
        this.animate(item, cur)
        $.animate(cur.el, {transform: `translateX(100%)`}, this.animDuration, this.easing)
    }

    animate(item, cur) {
        $.style(cur.el, "display", "flex")
        $.style(item.el, {
            position: "absolute",
            top: "0"
        })
        $.animate(item.el, {transform: `translateX(0)`}, this.animDuration, this.easing)
        $.end(item.el, this.endAnimation, this.animDuration)
    }

    endAnimation() {
        this.slideshow.items.forEach(item => {
            $.removeStyle(item.el, "transform")
            $.removeStyle(item.el, "position")
            $.removeStyle(item.el, "top")
            $.removeStyle(item.el, "display")
        })
    }
}

class Sequence {
    constructor(element, event, targets, animDuration, animator) {
        this.delegate = this.delegate.bind(this)
        this.stop = this.stop.bind(this)
        this.removeListeners = this.removeListeners.bind(this)
        this.buffer = this.buffer.bind(this)
        this.animate = this.animate.bind(this)
        this.retPos = this.retPos.bind(this)
        this.el = element
        this.targets = targets
        this.animator = animator
        this._brakeReturn = false
        this.origX = 0
        this.animDuration = animDuration
        this.easing = "ease-out"
        this.returnTimer = 0
        this._movingSide = 0
        const touches = event.touches || event.originalEvent.touches
        this.x = touches[0].pageX - this.origX
        this.y = touches[0].pageY
        this.width = $.width(this.el)
        this.vert = false
        this.setupItems()
        this.addListeners()
    }

    setupItems() {
        const side = this.side
        if(side == 0) return
        this.animTarget = this.targets[1 - side]
        $.style(this.animTarget, {
            transform: `translateX(${-100 * side}%)`,
            display: "flex",
            position: "absolute",
            top: "0"
        })
    }

    brakeReturn() {
        this._brakeReturn = true
    }

    set side(diff) {
        const side = diff > 0 ? 1 : diff < 0 ? -1 : 0
        const changed = side != this._movingSide
        this._movingSide = side
        changed && side != 0 && this.setupItems()
    }

    get side() {
        return this._movingSide
    }

    addListeners() {
        $.on(this.el, 'touchmove', this.delegate)
        $.on(this.el, 'touchend', this.stop)
        $.on(this.el, 'touchcancel', this.stop)
    }

    removeListeners() {
        $.off(this.el, 'touchmove', this.delegate)
        $.off(this.el, 'touchend', this.stop)
        $.off(this.el, 'touchcancel', this.stop)
    }

    delegate(event){
        if(!this.vert){
            this.buffer(event)
        }else{
            this.animate(event)
        }
    }

    buffer(event){
        const touches = event.touches || event.originalEvent.touches
        if(Math.abs(touches[0].pageX - this.x) > Math.abs(touches[0].pageY - this.y)){
            this.vert = !0
            this.animate(event)
        }
    }

    animate(event) {
        event.preventDefault();
        const touches = event.touches || event.originalEvent.touches
        const diff = touches[0].pageX - this.x
        const offset = - (100/this.width * diff)

        this.side = diff

        $.style(this.targets[1], {transform: `translateX(${-offset}%)`}, 0)
        $.style(this.animTarget, {transform: `translateX(${-offset - 100 * this.side}%)`}, 0)

        this.origX = diff
    }

    retPos(){
        if(this._brakeReturn) return
        $.animate(this.targets[1], {transform: `translateX(0)`}, this.animDuration, this.easing)
        $.animate(this.animTarget, {transform: `translateX(${-100 * this.side}%)`}, this.animDuration, this.easing)
        $.end(this.targets[1], this.animator.endAnimation, this.animDuration)
    }

    stop() {
        this.returnTimer = setTimeout(this.retPos, 1)
        this.removeListeners()
    }
}

class Slideshow extends Pager {
    constructor(containerSelector, childrenSel, slideContainerSel, bulletsContainerSel) {
        childrenSelector = childrenSel || childrenSelector
        slideContainerSelector = slideContainerSel || slideContainerSelector
        bulletsContainerSelector = bulletsContainerSel || bulletsContainerSelector
        const container = $.querySelectorAll(document, containerSelector)[0]
        const slideContainer = container ? $.querySelectorAll(container, slideContainerSelector)[0] : null
        const elements = slideContainer ? $.querySelectorAll(slideContainer, childrenSelector) : null
        super(elements, container)
        if(!container) return
        this.el = container
        this.slidesContainer = slideContainer
        this.bulletsContainer = $.querySelectorAll(container, bulletsContainerSelector)[0]
        this.bullets = []

        this.build()
        this.init()
    }

    initialize() {}

    init() {
        this.closeAll()
        this.openItem(this.items[0])
        this.animator = new Animator(this)
        this.hammer = new Hammer(this.slidesContainer)
        this.hammer.on("swipeleft", this.openNextItem.bind(this))
        this.hammer.on("swiperight", this.openPreviousItem.bind(this))
        $.class.addClass(this.el, "initialized")
    }

    build() {
        super.build()
        this.addBullets()
    }

    openPreviousItem() {
        const current = this.getCurrentlyOpen()
        const prev = this.getPreviousItem(current)
        super.openPreviousItem()
        this.animator.prev(prev, current)
    }

    openNextItem() {
        const current = this.getCurrentlyOpen()
        const next = this.getNextItem(current)
        super.openNextItem()
        this.animator.next(next, current)
    }

    openItem(item) {
        const current = this.getCurrentlyOpen()
        const curBullet = this.getBullet(current)
        const itemBullet = this.getBullet(item)
        super.openItem(item)
        curBullet && curBullet.close()
        itemBullet.open()
    }

    addBullets() {
        this.bullets = this.items.map(item => this.addBullet(item))
    }

    addBullet(item) {
        return new Bullet(item, this.bulletsContainer, this)
    }

    getBullet(item) {
        return this.bullets.filter(bullet => bullet.slide == item)[0]
    }

    closeAll() {
        this.items.forEach(item => item.close())
    }
}

export default Slideshow