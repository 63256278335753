import ToggleButton from '../ToggleButton/ToggleButton'
import menuButtonScss from './MenuButton.scss'

class MenuButton extends ToggleButton {
    constructor(selector, openCallback, closeCallback) {
        super(selector, openCallback, closeCallback)
        this.openClass = "open"
    }
}

export default MenuButton