import pagerScss from "./Pager.scss"
import OpenClose from "../OpenClose/OpenClose";
import Button from "../Button/Button"

const $ = require('dom-helpers')

const nextButtonClass = "next"
const prevButtonClass = "previous"

class Pager {
    constructor(elements, buttonContainer, nextBtnClass, prevBtnClass) {
        this.elements = elements
        this.buttonContainer = buttonContainer
        this.items = []
        this.nextButtonClass = nextBtnClass || nextButtonClass
        this.prevButtonClass = prevBtnClass || prevButtonClass
        this.initialize()
    }

    initialize() {
        this.build()
    }

    build() {
        this.assemble()
        this.addButtons()
    }

    addButtons() {
        this.prevBtn = new Button(
            "span",
            this.nextButtonClass,
            this.openNextItem.bind(this)
        )
        this.nextBtn = new Button(
            "span",
            this.prevButtonClass,
            this.openPreviousItem.bind(this)
        )
        if(this.items.length > 1) {
            this.buttonContainer.appendChild(this.prevBtn.el)
            this.buttonContainer.appendChild(this.nextBtn.el)
        }
    }

    openPreviousItem() {
        const current = this.getCurrentlyOpen()
        const prev = this.getPreviousItem(current)
        this.openItem(prev)
    }

    openNextItem() {
        const current = this.getCurrentlyOpen()
        const next = this.getNextItem(current)
        this.openItem(next)
    }

    openItem(item) {
        const current = this.getCurrentlyOpen()
        current && current.close()
        item.open()
    }

    getCurrentlyOpen() {
        return this.items.filter(item => item.isOpen)[0]
    }

    getPreviousItem(item) {
        var index = this.items.indexOf(item)
        return index == 0 ? this.items[this.items.length - 1] : this.items[index - 1]
    }

    getNextItem(item) {
        var index = this.items.indexOf(item)
        return index == this.items.length - 1 ? this.items[0] : this.items[index + 1]
    }

    assemble() {
        this.items = this.elements.map(element => {
            return this.addItem(element)
        })
    }

    addItem(element) {
        return new OpenClose(element)
    }
}

export default Pager