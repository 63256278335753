const $ = require('dom-helpers')

class OpenClose {
    constructor (element) {
        this.openClass = "on"
        this.open = this.open.bind(this)
        this._isOpen = false
        this.el = element
    }

    get isOpen() { return this._isOpen }

    set isOpen(val) {
        this._isOpen = val
		val ? this._open() : this._close()
    }

    open() { this.isOpen = true }

    close() { this.isOpen = false }

    _open() {
        $.class.addClass(this.el, this.openClass)
    }

    _close() {
        $.class.removeClass(this.el, this.openClass)
    }
}

export default OpenClose