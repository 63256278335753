import OpenClose from "../OpenClose/OpenClose"

const $ = require('dom-helpers')

class ToggleButton extends OpenClose {
    constructor(selector, openCallback, closeCallback) {
        const el = $.querySelectorAll(document, selector)[0]
        super(el)
        this.openCallback = openCallback
        this.closeCallback = closeCallback
        this.addListeners()
    }

    addListeners() {
        $.on(this.el, "click", this.toggle.bind(this))
    }

    _open() {
        super._open()
        if(typeof this.openCallback === 'function') this.openCallback()
    }

    _close() {
        super._close()
        if(typeof this.closeCallback === 'function') this.closeCallback()
    }

    toggle() { this.isOpen = !this.isOpen }
}

export default ToggleButton