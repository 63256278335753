const $ = require('dom-helpers')
$.class = require('dom-helpers/class')

class Element {
    constructor(type, className, parent) {
        this.el = document.createElement(type)
        className && this.addClassName(className)
        parent && this.appendTo(parent)
    }

    static create(type, className, parent) {
        return new Element(type, className, parent).el
    }

    addClassName(className) {
        $.class.addClass(this.el, className)
    }

    removeClassName(className) {
        $.class.removeClass(this.el, className)
    }

    appendTo(parent) {
        parent.appendChild(this.el)
    }
}

export default Element