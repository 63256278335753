import sliderScss from "./Slider.scss"
import Button from "../Button/Button"
import Element from "../Element/Element"
import _ from "underscore"

const $ = require('dom-helpers')

const leftButtonClass = "previous"
const rightButtonClass = "next"
const innerClass = "slider-inner"
const hiddenClass = "hidden"

class Slider {
    constructor(container) {
        if(!container) return
        this.container = container
        this.children = []
        this.assembleChildren()
        this.inner = Element.create("div", innerClass, this.container)
        this.addItems(this.children)
        this.addButtons()
        this.checkNavigableDirections(0)
        this.addEventListeners()
    }

    addButtons() {
        this.leftBtn = new Button(
            "span",
            leftButtonClass,
            this.slideLeft.bind(this),
            this.container
        )
        this.rightBtn = new Button(
            "span",
            rightButtonClass,
            this.slideRight.bind(this),
            this.container
        )
    }

    addEventListeners() {
        this.intersectionObserver = new IntersectionObserver(this.checkNavigableDirections.bind(this), {root: this.container})
        this.intersectionObserver.observe(this.inner)
    }

    assembleChildren() {
        this.container.childNodes.forEach(child => this.children.push(child))
    }

    addItems(items) {
        items.forEach(item => this.addItem(item));
    }

    addItem(item) {
        this.inner.appendChild(item)
    }

    slideLeft() {
        const left = this.getItemClosestToEdge(-$.width(this.container))
        this.moveToItem(left)
    }

    slideRight() {
        const right = this.getItemClosestToEdge($.width(this.container))
        this.moveToItem(right)
    }

    getItemClosestToEdge(edge) {
        return _.min(this.children, item => {
            const pos = $.position(item).left
            const distToEdge = edge - pos
            return Math.abs(distToEdge)
        })
    }

    checkNavigableDirections(pos) {
        const sliderPos = _.isNumber(pos) ? pos : $.position(this.inner).left
        const containerWidth = $.width(this.container)
        const sliderWidth = $.width(this.inner)
        if(sliderPos >= 0) {
            this.leftBtn.addClassName(hiddenClass)
        } else {
            this.leftBtn.removeClassName(hiddenClass)
        }
        if(sliderWidth + sliderPos <= containerWidth) {
            this.rightBtn.addClassName(hiddenClass)
        } else {
            this.rightBtn.removeClassName(hiddenClass)
        }
    }

    moveToItem(item) {
        const itemPos = $.position(item, this.inner).left
        this.moveTo(itemPos)
    }

    moveTo(pos) {
        const rightConstraint = $.width(this.inner) - $.width(this.container)
        const targ = Math.min(rightConstraint, pos)
        $.style(this.inner, "transform", `translateX(-${targ}px)`)
        this.checkNavigableDirections(-targ)
    }

    get slideElement() {
        return this.inner
    }
}

export default Slider