import $ from "dom-helpers"

class ItemAspect {
    constructor(itemSelector, aspectRatio) {
        this.aspect = aspectRatio || 1
        this.elements = $.querySelectorAll(document, itemSelector)
        this.apply()
        this.addListeners()
    }

    addListeners() {
        $.on(window, 'resize', this.apply.bind(this))
        $.on(window, 'orientationchange', this.apply.bind(this))
    }

    apply() {
        this.elements.forEach(element => {
            const width = parseInt($.style(element, 'width'))
            const height = (width * this.aspect) + "px"
            $.style(element, 'height', height)
        });
    }
}

export default ItemAspect