import pDFGeneratorScss from "./PDFGenerator.scss"
import $ from "dom-helpers"
import _ from "underscore"

const buttonSelector = "button"
const textAreaSelector = "textarea"
const textMaxCounts = [60, 60, 60]
const countsDisplaysSelector = ".counts li"
const previewLinesSelector = ".preview-content > div"
const textValueLinesSelector = "input[name^=line]"
const posterTextSelector = ".posterText"

class PDFGenerator {
    constructor(elementSelector) {
        this.handleInput = this.handleInput.bind(this)
        this.countChars = this.countChars.bind(this)
        this.outputCounts = this.outputCounts.bind(this)
        this.el = $.querySelectorAll(document, elementSelector)[0]
		if(!this.el) return

        this.textarea = $.querySelectorAll(this.el, textAreaSelector)[0]
		this.button = $.querySelectorAll(this.el, buttonSelector)[0]

        this.countsDisplays = $.querySelectorAll(this.el, countsDisplaysSelector)
        this.previewLines = $.querySelectorAll(this.el, previewLinesSelector)
        this.textValueLines = $.querySelectorAll(this.el, textValueLinesSelector)
        console.log(this.textValueLines)
        this.addListeners()
        this.countChars()
        this.createPreview()
        this.setFormValues()
    }

    addListeners() {
		$.on(this.textarea, "keyup", this.handleInput)
    }

    handleInput(e) {
        const rows = this.getTextRows()
        this.countChars()
        this.createPreview()
        this.setFormValues()
    }

    createPreview() {
        const rows = this.getTextRows()
        this.previewLines.forEach((line, index) => {
            line.innerText = rows && rows[index] ? rows[index] : ""
        })
    }

    setFormValues() {
        const rows = this.getTextRows()
        this.textValueLines.forEach((line, index) => {
            line.value = rows && rows[index] ? rows[index] : ""
        })
    }

    getTextRows() {
        return this.textarea.value.match(/[^\r\n]+/g)
    }

    getSelectedSize() {
        return $.querySelectorAll(this.el, `${sizeFieldSelector}:checked`)[0].value
    }

    countChars() {
        const rows = this.getTextRows()
        const counts = rows ? rows.map(row => row.length) : 0
        this.outputCounts(counts)
    }

    outputCounts(counts) {
        this.countsDisplays.forEach((display, index) => display.innerHTML = counts[index] ? `${counts[index]}/${textMaxCounts[index]}`: "")
    }
}

export default PDFGenerator