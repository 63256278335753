import OpenClose from "../OpenClose/OpenClose"

import Tracking from '../Tracking/Tracking'

import Overlay from '../Overlay/Overlay'
const $ = require('dom-helpers')

class GalleryItem extends OpenClose {
    constructor(element, overlaySelector, gallery) {
        super(element)
        this.gallery = gallery
        this.overlaySelector = overlaySelector
		this.overlay = new Overlay(this.overlaySelector, this.el, this)
        this.addListeners()
    }

    open(e) {
		super.open()
    }

    _open() {
        this.overlay.open()
        this.gallery.checkOpen()
    }

    _close() {
        this.overlay.close()
        this.gallery.checkOpen()
    }

    addListeners() {
        $.on(this.el, "click", this.open)
    }
}

export default GalleryItem