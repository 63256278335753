import OpenClose from "../OpenClose/OpenClose"
import tabsScss from "./Tabs.scss"

const $ = require('dom-helpers')
let selectors = {
    tab: ".tab",
    head: ".tab-head",
    content: ".tab-content"
}
const openClass = "active"

class Tab extends OpenClose {
    constructor(element, headerContainer, tabs) {
        super(element)
        this.openClass = openClass
        this.tabs = tabs
        this.headerContainer = headerContainer
        this.head = $.querySelectorAll(this.el, selectors.head)[0]

        this.headerContainer.appendChild(this.head)
        this.addListeners()
    }

    open(e) {
        e && e.preventDefault()
        super.open()
    }

    _open() {
        super._open()
        $.class.addClass(this.head, openClass)
    }

    _close() {
        super._close()
        $.class.removeClass(this.head, openClass)
    }

    addListeners() {
        $.on(this.head, "click", () => this.tabs.openItem(this))
    }
}

class Tabs {
    constructor(containerSelector, tabSelector, headSelector, contentSelector) {
        this.openItem = this.openItem.bind(this)
        selectors = {
            tab: tabSelector || selectors.tab,
            head: headSelector || selectors.head,
            content: contentSelector || selectors.content
        }
        this.el = this.container = $.querySelectorAll(document, containerSelector)[0]
        if(!this.el) return
        this.elements = $.querySelectorAll(this.container, selectors.tab)
        this.assemble()
        this.items[0].open()
        $.class.addClass(this.el, "initialized")
    }

    assemble() {
        this.tabHeadContainer = document.createElement("div")
        this.container.insertBefore(this.tabHeadContainer, this.container.firstChild)

        this.items = this.elements.map(element => {
            return new Tab(element, this.tabHeadContainer, this)
        })
    }

    openItem(item) {
        const cur = this.getCurrentlyOpen()
        cur && cur.close()
        item.open()
    }

    getCurrentlyOpen() {
        return this.items.filter(item => item.isOpen)[0]
    }
}

export default Tabs