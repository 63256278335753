import MenuButton from '../MenuButton/MenuButton'
import MenuScss from './Menu.scss'

const $ = require('dom-helpers')

const openClass = 'open-menu'

class Menu {
    constructor(menuSelector, menuButtonSelector) {
        this.el = $.querySelectorAll(document, menuSelector)[0]
        
        new MenuButton(menuButtonSelector, this.open, this.close)
    }

    open() {
        $.class.addClass(document.children[0], openClass)
    }

    close() {
        $.class.removeClass(document.children[0], openClass)
    }
}

export default Menu