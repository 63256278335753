import '../core/foundation.scss'
//import '../core/svgicons.scss'
import '../core/base.scss'

import $ from "dom-helpers"
import Header from "../modules/Header/Header"
import Breadcrumbs from "../modules/Breadcrumbs/Breadcrumbs"
import Footer from "../modules/Footer/Footer"
import Menu from "../modules/Menu/Menu"
import TeaserList from "../modules/TeaserList/TeaserList"
import TeaserListCompact from "../modules/TeaserListCompact/TeaserListCompact"
import ItemAspect from '../modules/ItemAspect/ItemAspect'
import Select from "../modules/Select/Select"
import Paginator from "../modules/Paginator/Paginator"
import Gallery from "../modules/Gallery/Gallery"
import Tabs from "../modules/Tabs/Tabs"
import PostGallery from "../modules/PostGallery/PostGallery"
import Wizard from "../modules/Wizard/Wizard"
import PDFGenerator from "../modules/PDFGenerator/PDFGenerator"
import DownloadsList from "../modules/DownloadsList/DownloadsList"
import PortraitDownloadsList from "../modules/PortraitDownloadsList/PortraitDownloadsList"
import DocumentPreview from "../modules/DocumentPreview/DocumentPreview"
import Tracking from '../modules/Tracking/Tracking';

new Menu("nav", "nav .menuButton")
$.querySelectorAll(document, ".teaserlist.gallery").forEach(list => new Gallery(list, ".teaser"))
new ItemAspect('.teaserlist.compact.square .teaser')
new Tabs(".tabs")
new PostGallery(".postgallery")
new Wizard(".wizard")
new PDFGenerator(".pdf-generator")
new Select(".select.option-href")


setTimeout(() => {







// 4.1 trackMenus
// .track-great-looking-menus .teaser
// 'eventCategory': 'Great Looking Menus'
// 'eventAction': 'View'
// 'eventLabel': .img img[alt]

$.querySelectorAll(document, '.track-great-looking-menus .teaser').forEach(teaser => {
	const eventLabel = $.querySelectorAll(teaser, '.img img')[0].getAttribute('alt')
	$.on(teaser, "click", () => {
		// console.log('clicked')

		new Tracking({
			eventCategory: 'Great Looking Menus',
			eventAction: 'View',
			eventLabel,
		}).push()
	})
})



// 4.2 trackMenus
// .track-great-parings-ready-to-use .teaser
// 'eventCategory': 'Great Parings Ready to Use'
// 'eventAction': 'View'
// 'eventLabel': .img img[alt]

$.querySelectorAll(document, '.track-great-parings-ready-to-use .teaser').forEach(teaser => {
	const eventLabel = $.querySelectorAll(teaser, '.img img')[0].getAttribute('alt')
	$.on(teaser, "click", () => {
		// console.log('clicked')

		new Tracking({
			eventCategory: 'Great Parings Ready to Use',
			eventAction: 'View',
			eventLabel,
		}).push()
	})
})




// 4.3 trackPdfGenerator
// .pdf-generator .button.generate
// 'eventCategory': 'Download'
// 'eventAction': 'Generate PDF'
// 'eventLabel': h1

$.querySelectorAll(document, '.pdf-generator form').forEach(button => {
	const eventLabel = document.querySelector('h1').innerText

	$.on(button, "submit", (e) => {
		// console.log('clicked')
		new Tracking({
			eventCategory: 'Download',
			eventAction: 'Generate PDF',
			eventLabel,
		}).push()
		setTimeout(() => {
			// prevent tracking errors
			button.submit()
		}, 500)
	})
})


// 5.1 trackVideos
// .teaserlist .teaser.video
// 'eventCategory': 'Video clicks'
// 'eventAction': window.location.pathname + window.location.search
// 'eventLabel': .teaser .overlay h2

$.querySelectorAll(document, '.teaser.video').forEach(teaser => {
	const eventLabel = $.querySelectorAll(teaser, '.overlay h2')[0].innerText
	// console.log($.querySelectorAll(teaser, '.overlay h2')[0])

	$.on(teaser, "click", () => {
		// console.log('clicked')

		new Tracking({
			eventCategory: 'Video clicks',
			eventAction: window.location.pathname + window.location.search,
			eventLabel,
		}).push()
	})
})








}, 100);