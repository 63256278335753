import galleryScss from './Gallery.scss'
import GalleryItem from './GalleryItem'
import Pager from '../Pager/Pager'
import Element from '../Element/Element'
import _ from "underscore"

const $ = require('dom-helpers')
let overlaySelector = ".overlay"
const nextButtonClass = "next"
const prevButtonClass = "previous"
const controlsContainerClass = "gallery-controls"
const activeClass = "active"

class Gallery extends Pager {
    constructor(containerOrSelector, childrenSelector, overlaySel) {
        const container = _.isElement(containerOrSelector) ? containerOrSelector : $.querySelectorAll(document, containerOrSelector)[0]
        const elements = $.querySelectorAll(container, childrenSelector)
        const controlsContainer = Element.create("div", controlsContainerClass, container)
        super(elements, controlsContainer)
        this.checkOpen = this.checkOpen.bind(this)
        overlaySelector = overlaySel || overlaySelector
        this.build()
        this.checkOpen()
    }

    initialize() {}

    checkOpen() {
        this.getCurrentlyOpen() ? $.class.addClass(this.buttonContainer, activeClass) : $.class.removeClass(this.buttonContainer, activeClass)
    }

    addItem(element) {
        return new GalleryItem(element, overlaySelector, this)
    }
}

export default Gallery