import './Overlay.scss'

const $ = require('dom-helpers')

const openClass = "show"
const bodyLockClass = "overlay-lock"
const closeButtonClass = "close"
const contContainerSelector = ".overlay-container"

const copyButtonSelector = "button.copy"
const postContentSelector = ".post-content"

class Overlay {
    constructor(element, container, controller) {
        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
        this.controller = controller
		this.container = container

        this.el = $.querySelectorAll(this.container, element)[0]
		if(!this.el) return

        this.cont = $.querySelectorAll(this.el, contContainerSelector)[0]
		this.closeBtn = document.createElement("span")

        $.class.addClass(this.closeBtn, closeButtonClass)
		this.cont.appendChild(this.closeBtn)

		this.copyBtn = $.querySelectorAll(this.el, copyButtonSelector )[0] || false
		this.postContent = $.querySelectorAll(this.el, postContentSelector )[0]

		this.addListeners()
    }

    addListeners() {
        $.on(this.el, "click", e => {
            if(e.target == this.el || e.target == this.closeBtn) {
                e.preventDefault()
                e.stopPropagation()
            }
            if(e.target.closest(contContainerSelector)) return
            this.controller.close()
        })
		$.on(this.closeBtn, "click", () => this.controller.close())

		if (!this.copyBtn) return false
		$.on(this.copyBtn, "click", () => this.copyText())
    }

    open() {
        $.class.addClass(this.el, openClass)
		$.class.addClass(document.body, bodyLockClass)
		this.selectWholeText()
    }

    close() {
        $.class.removeClass(this.el, openClass)
		$.class.removeClass(document.body, bodyLockClass)
		this.closeVideo()
	}

	selectWholeText() {
		try {
			this.postContent.focus()
		} catch (e) { }

		setTimeout(() => {
			try {
				this.postContent.focus()
			} catch (e) { }
		}, 50)
	}

	copyText() {
		this.selectWholeText()

		setTimeout(() => {
			document.execCommand("copy")
		}, 150)
	}

	closeVideo() {
		const videos = $.querySelectorAll(this.el, 'video')
		videos.forEach( video => {
			video.pause()
			video.currentTime = 0
		})
	}
}

export default Overlay