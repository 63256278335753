import Element from "../Element/Element"

const $ = require('dom-helpers')

class Button extends Element {
    constructor(type, className, clickCallback, parent) {
        super(type, className, parent)
        this.callback = clickCallback

        this.callback && this.addListeners(this.callback)
    }

    addListeners(callback) {
        $.on(this.el, "click", e => callback(e))
    }
}

export default Button