import postGalleryScss from "./PostGallery.scss"
import Slideshow from "../Slideshow/Slideshow";
import Bullet from "../Slideshow/Bullet";
import Slider from "../Slider/Slider"

const $ = require('dom-helpers')

class GalleryBullet extends Bullet {
    constructor(slide, container, slideshow) {
        super(slide, container, slideshow)
        const img = $.querySelectorAll(this.slide.el, '.image img')[0].cloneNode(true)
        this.el.appendChild(img)
    }
}

class PostGallery extends Slideshow {
    constructor(containerSelector, childrenSel, slideContainerSel, bulletsContainerSel){
        super(containerSelector, childrenSel, slideContainerSel, bulletsContainerSel)
        this.slider = new Slider(this.bulletsContainer)
        this.bulletsContainer = this.slider.slideElement
    }

    openItem(item) {
        super.openItem(item)
        this.slider && this.slider.moveToItem(this.getBullet(item).el)
    }

    addBullet(item) {
        return new GalleryBullet(item, this.bulletsContainer, this)
    }
}

export default PostGallery